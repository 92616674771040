import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import { Helmet } from 'react-helmet';
import successImg from "../images/checked.png"
import {Link} from "gatsby"
import {useTranslation} from 'gatsby-plugin-react-i18next'
import{graphql} from "gatsby"
const ContactSuccessful = () => {
  const {t} = useTranslation();
  return(
    <div className="freeTrialSuccessful">
      <HeardTopWrapper title={t('contactSuccessful')}/>
      <Layout>
        <Helmet>
            <title>{t('myKitchenInspector')}{t('contactSuccessful')}</title>
        </Helmet>
        <PageContainer >
          <div className="text-center">
            <div className="flexItem">
              <img
                    className="successImg"
                    src={successImg}
                    width="115"
                    height="115"
                    alt="header logo"
                  />
              <div>
                <h2>
                {t('thankYou')}
                </h2>
                <p>
                {t('contactSuccessDes')}
                </p>
                <p>{t('backTo')} <Link to="/">{t('homepage')}</Link></p>
              </div>
            </div>
          </div>
        </PageContainer>
      </Layout>
    </div>
  )
}
export default ContactSuccessful
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
